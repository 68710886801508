import React from 'react'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { Nav } from 'components/nav'
import { HeaderLink, LdJsonLogo } from 'components/semanticdata'
import Analytics from '../../../components/analytics'
import { Link } from 'gatsby'
import { BackgroundWithGrid } from '../../../components/layout'

const BlogPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO title="The Next Tech Challenge" pageUrl="/initiatives/24-techchallenge/" type="article" description="Tumba Solutions Launches 'The Next Tech Challenge' to Empower Startups and Scaleups" cardImage="/img/tumbacard24techchallenge.png" />
    <LdJsonLogo />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 mb-72 sm:h-48">
        <HeaderLink className="text-primary-2" />
        <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow hidden">The Next Tech Challenge</h1>
      </Header>
      <BackgroundWithGrid image="24-techchallenge-hero" gridRow={1} />
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20 lg:mr-48" gridRow={2}>
        <h2 className="text-2xl md:text-3xl lg:text-4xl text-primary-1">
          Tumba Solutions Launches
          <br />
          "The Next Tech Challenge"
          <br />
          to Empower Startups and Scaleups
        </h2>
        <div className="mt-4 mb-16 text-lg lg:text-xl tracking-normal font-normal">
          <p className="mt-6">
            <em>
              "<strong className="text-primary-1 font-normal">The Next Tech Challenge</strong> is about empowering these companies to excel by overcoming tech barriers that many shy away from" shared{' '}
              <Link to="https://www.linkedin.com/in/emoabj/" className="text-primary-1">
                Emo Abadjiev
              </Link>
              , CEO of Tumba Solutions, discussing his vision for the initiative.
            </em>
          </p>
          <p className="mt-4 md:mt-5 lg:mt-6">
            <Link to="/">
              <strong className="text-primary-1 font-normal">Tumba Solutions</strong>
            </Link>
            , renowned for its integration of cutting-edge software technology in various industry sectors including Automotive, Digital Twin planning, Media, and Health, has introduced "
            <strong className="text-primary-1 font-normal">The Next Tech Challenge</strong>". This new initiative, led by CEO <strong className="text-primary-1 font-normal">Emo Abadjiev</strong>, aims to assist startups and scaleups facing technological
            obstacles, enabling them to concentrate on their core business development.
          </p>
          <p className="mt-4 md:mt-5 lg:mt-6">
            <em>
              "Drawing from our extensive experience with Fortune 500 companies and fueled by our team’s passion for tackling complex challenges, we are well-equipped to understand the unique pressures that emerging companies encounter in today’s competitive
              tech landscape."
            </em>
          </p>
          <p className="mt-4 md:mt-5 lg:mt-6">
            <strong className="text-primary-1 font-normal">Tumba Solutions</strong> is renowned for its comprehensive approach to technology, offering an extensive array of services that range from proof of concept and product growth to tech ownership and team
            extensions. Their portfolio spans the development of high-performance software across key sectors including Autonomous Transportation, Media & Entertainment, Digital City, and Digital Health. The company is consistently expanding its expertise in
            the field of AI, focusing on training and integrating AI technologies in various business scenarios.
          </p>
          <p className="mt-4 md:mt-5 lg:mt-6">
            Through "<strong className="text-primary-1 font-normal">The Next Tech Challenge</strong>", Tumba Solutions will provide a platform for startups and scaleups to showcase their projects and collaborate directly with Tumba’s experts to refine and
            enhance their technological frameworks. This collaboration is part of Tumba's ongoing commitment to fostering sustainable business growth and building long-term partnerships.
          </p>
          <p className="mt-4 md:mt-5 lg:mt-6">
            This program is poised to be a crucial resource not only for young companies striving to navigate the complexities of product development and market integration, ensuring they maintain a cutting edge in technology and business innovation, but also
            for companies that need technology solutions in order to scale faster, meeting the requirements of the fast-changing tech landscape.
          </p>
          <p className="mt-4 md:mt-5 lg:mt-6">
            Startups and scaleups interested in participating in "<strong className="text-primary-1 font-normal">The Next Tech Challenge</strong>" are encouraged to apply through the form available{' '}
            <a href="/initiatives/24-techchallenge/form/" className="text-primary-1">
              here
            </a>
            .
          </p>
        </div>
      </Section>
      <Nav gridRow={7} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default BlogPage
